import { Component, Input, OnChanges } from '@angular/core'
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner'

@Component({
    selector: 'spinner',
    template: `
        <ngx-spinner bdColor="rgba(51,51,51,0.4)" size="medium" color="#fff" type="ball-scale-multiple">
            <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
    `,
    standalone: true,
    imports: [NgxSpinnerModule],
})
export class SpinnerComponent implements OnChanges {
    @Input() condition = false

    constructor(private spinner: NgxSpinnerService) {}

    ngOnChanges(simpleChanges) {
        if (simpleChanges.condition.currentValue) this.spinner.show()
        else this.spinner.hide()
    }
}
